import { useCallback, useEffect, useReducer } from "react";
import styled from "styled-components";
import {
  B2B_ACCEPT_INVITE_URL_LOGGED_IN_URL,
  B2B_ACCEPT_INVITE_URL_NOT_LOGGED_IN_URL,
} from "@telia/b2b-utils";

import { ADD_INFORMATION_STEP, SUCCESS } from "./components/constants";
import ErrorMessage from "./components/ErrorMessage";
import Hero from "./components/Hero";
import InviteError from "./components/InviteError";
import InviteSkeleton from "./components/InviteSkeleton";
import RequestCode from "./components/RequestCode";
import { Stepper } from "./components/Stepper";
import Success from "./components/Success";
import { useInviteInformation } from "./hooks/useInviteInformation";
import { useIsLoggedIn } from "./hooks/useIsLoggedIn";
import { getInviteIdFromUrl, getNonceFromUrl } from "./utils/utils";

const Wrapper = styled.div`
  background-color: var(--purpur-color-background-secondary);
`;

enum State {
  SUCCESS_PAGE,
  REQUEST_CODE,
  STEPPER,
  LOADING,
  INVITE_ERROR = 4,
  TECHNICAL_ERROR,
}

enum FlowAction {
  UNKNOWN,
  SMS_CODE_REQUESTED,
  AUTHENTICATED,
  COMPLETED_INVITE,
}

export default function App() {
  const {
    data: inviteInformation,
    isError: isInviteError,
    isLoading: isInviteLoading,
    isFetched: isInviteFetched,
  } = useInviteInformation();
  const {
    data: isLoggedIn,
    isLoading: isLoggedInLoading,
    isFetched: isLoggedInFetched,
    isError: isLoggedInError,
  } = useIsLoggedIn();
  const [state, dispatchState] = useReducer(appStateReducer, State.LOADING);

  useEffect(() => {
    dispatchState(FlowAction.UNKNOWN);
  }, [isInviteFetched, isLoggedInFetched]);

  function appStateReducer(state: State, action: FlowAction): State {
    if (isInviteError) {
      return State.INVITE_ERROR;
    }
    if (isLoggedInError) {
      return State.TECHNICAL_ERROR;
    }
    if (isLoggedIn) {
      const url = constructUrl();
      history.replaceState(null, "", url);
    }
    const shouldRenderSuccessPage = window.location.href.includes(SUCCESS);
    if (isLoggedIn && shouldRenderSuccessPage) {
      return State.SUCCESS_PAGE;
    }

    switch (action) {
      case FlowAction.UNKNOWN:
        return whatIsTheState();
      case FlowAction.SMS_CODE_REQUESTED:
      case FlowAction.AUTHENTICATED:
        return State.STEPPER;
      case FlowAction.COMPLETED_INVITE:
        return State.SUCCESS_PAGE;
    }
  }

  function whatIsTheState(): State {
    if (isInviteLoading || isLoggedInLoading) {
      return State.LOADING;
    }
    if (!isLoggedIn) {
      const url = constructUrl();
      history.replaceState(null, "", url);
    }

    if (
      window.location.href.includes(ADD_INFORMATION_STEP) &&
      isLoggedInFetched &&
      isInviteFetched
    ) {
      if (isLoggedIn) {
        return State.STEPPER;
      }
    }

    if (inviteInformation?.shouldSendSmsCode) {
      return State.REQUEST_CODE;
    } else {
      return State.STEPPER;
    }
  }
  function constructUrl(): string {
    const nonce = getNonceFromUrl();
    const inviteId = getInviteIdFromUrl();
    const baseUrl = isLoggedIn
      ? B2B_ACCEPT_INVITE_URL_LOGGED_IN_URL
      : B2B_ACCEPT_INVITE_URL_NOT_LOGGED_IN_URL;
    const returnFromCiam = window.location.pathname.includes(ADD_INFORMATION_STEP)
      ? ADD_INFORMATION_STEP
      : "";
    return baseUrl + "/" + inviteId + "/" + nonce + returnFromCiam + window.location.search;
  }

  const codeRequested = (success: boolean) => {
    if (success) {
      dispatchState(FlowAction.SMS_CODE_REQUESTED);
    }
  };

  const renderComponent = useCallback(() => {
    switch (state) {
      case State.LOADING: {
        return <InviteSkeleton />;
      }
      case State.SUCCESS_PAGE: {
        return <Success />;
      }
      case State.STEPPER: {
        return (
          <>
            <Hero
              invitedBy={inviteInformation?.invitedBy ?? ""}
              organisation={inviteInformation?.organisation ?? ""}
            />
            <Stepper
              emitAddUserSuccess={() => dispatchState(FlowAction.COMPLETED_INVITE)}
              showRequestSmsTokenButton={!!inviteInformation?.shouldSendSmsCode}
            />
          </>
        );
      }
      case State.REQUEST_CODE: {
        return (
          <>
            <Hero
              invitedBy={inviteInformation?.invitedBy ?? ""}
              organisation={inviteInformation?.organisation ?? ""}
            />
            <RequestCode
              emitTokenReceived={codeRequested}
              maskedMobilePhoneNumber={inviteInformation?.maskedMobilePhoneNumber ?? ""}
            />
          </>
        );
      }
      case State.INVITE_ERROR: {
        return <InviteError />;
      }
      case State.TECHNICAL_ERROR: {
        return <ErrorMessage type="technical-error" />;
      }
    }
  }, [
    inviteInformation?.invitedBy,
    inviteInformation?.maskedMobilePhoneNumber,
    inviteInformation?.organisation,
    inviteInformation?.shouldSendSmsCode,
    state,
  ]);

  return <Wrapper data-testid="app">{renderComponent()}</Wrapper>;
}
