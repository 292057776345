import { PropsWithChildren } from "react";
import { MessageProvider } from "@messageformat/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { useMessageLocale } from "../../hooks/useMessageLocale";
import ErrorBoundary from "../ErrorBoundary";
import ErrorMessage from "../ErrorMessage";
import InviteSkeleton from "../InviteSkeleton";

export function Scaffolding({ children }: PropsWithChildren<unknown>) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
        retry: false, // set in local query when you need this
      },
    },
  });
  const messages = useMessageLocale();

  if (!messages) {
    return <InviteSkeleton />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <MessageProvider messages={messages}>
        <ErrorBoundary fallback={<ErrorMessage type="technical-error" />}>{children}</ErrorBoundary>
      </MessageProvider>
    </QueryClientProvider>
  );
}
