import { useEffect, useState } from "react";
import { MessageObject } from "@messageformat/react";
import { currentLanguage, setCurrentLanguage } from "@telia/b2b-i18n";

import en from "../locales/locale.en.yaml";
import sv from "../locales/locale.sv.yaml";
const setLanguageKey = "setLanguage";

export function useMessageLocale() {
  const [messages, setMessages] = useState<MessageObject>();

  const params = new URLSearchParams(window.location.search);
  const languageQueryParam = params.get(setLanguageKey);
  const [currentLocale] = useState<string>(languageQueryParam ?? currentLanguage());

  if (languageQueryParam || params.size > 0) {
    params.delete(setLanguageKey);
    history.replaceState(
      null,
      "",
      `${window.location.pathname}${params.size > 0 ? `?${params.toString()}` : ""}`
    );
    if (languageQueryParam === "en" || languageQueryParam === "sv") {
      setCurrentLanguage(languageQueryParam);
    } else {
      setCurrentLanguage("sv");
    }
  }

  useEffect(() => {
    if (currentLocale === "en") {
      setMessages(en);
    } else {
      setMessages(sv);
    }
  }, [currentLocale]);

  return messages;
}
