import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useMessage } from "@messageformat/react";
import { Button } from "@purpur/purpur";
import { Heading } from "@purpur/purpur";
import { Link } from "@purpur/purpur";
import { TextField } from "@purpur/purpur";
import { useRequestSmsToken } from "../../../hooks/useRequestSmsToken";
import { useValidateSmsToken } from "../../../hooks/useValidateSmsToken";
import { trackVerifyOneTimeCode } from "../../../utils/analytics";
import Layout from "../../Layout";
import { PaddedParagraph, StyledFieldError } from "../../StyledComponents";
import { trackCodeInput, trackRequestNewToken } from "../../../utils/ga4Analytics";

export interface StepContentProps {
  slot: string;
  showRequestSmsTokenButton: boolean;
  emitVerifyCode: () => void;
  emitValidationCode: (validationCode: string) => void;
}
const FlexedDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: var(--purpur-spacing-600);
`;
const StyledLink = styled.span`
  margin-left: var(--purpur-spacing-300);
`;

function VerifyCodeStep(props: StepContentProps) {
  const messages = useMessage(`verify-code`);
  const codeCoolDownText = useMessage("codeCoolDown");
  const { slot, showRequestSmsTokenButton, emitVerifyCode, emitValidationCode } = props;
  const textInput = useRef("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [code, setCode] = useState<string>();
  const { data, isLoading: validateCodeIsLoading } = useValidateSmsToken(code);
  const { isError, mutate: requestSmsToken, isLoading } = useRequestSmsToken();

  const handleRequestSmsToken = () => {
    trackRequestNewToken();

    requestSmsToken();
  };

  const verifyCode = (code: string) => {
    trackCodeInput();
    setCode(code);
  };

  useEffect(() => {
    if (validateCodeIsLoading) {
      return;
    }

    if (data?.valid) {
      emitValidationCode(data?.validationCode ?? "");
      emitVerifyCode();
      trackVerifyOneTimeCode();
      return;
    } else if (!data?.valid) {
      return setErrorMessage(messages.codeError());
    }
  }, [data, isLoading]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    textInput.current = event.currentTarget.value;
  };

  return (
    <Layout slot={slot} dataTestId="verify-code-step">
      <Heading tag="h1" variant="title-300">
        {messages.title()}
      </Heading>

      <PaddedParagraph variant="preamble-100">{messages.preamble()}</PaddedParagraph>
      <TextField id="verifyCodeInput" onInput={onInputChange} label={messages.inputLabel()} />
      {errorMessage && <StyledFieldError>{errorMessage}</StyledFieldError>}

      <FlexedDiv>
        <Button
          variant="primary"
          type="button"
          size="md"
          fullWidth={false}
          onClick={() => verifyCode(textInput.current)}
          data-testid="button-verify-code"
        >
          {messages.button()}
        </Button>
        {showRequestSmsTokenButton && (
          <StyledLink>
            <Link variant="standalone">
              <a onClick={() => handleRequestSmsToken()}>{messages.link()}</a>
            </Link>
          </StyledLink>
        )}
      </FlexedDiv>
      {isError && (
        <StyledFieldError data-testid="code-cool-down">{codeCoolDownText}</StyledFieldError>
      )}
    </Layout>
  );
}
export default VerifyCodeStep;
