import { Component, ErrorInfo, PropsWithChildren, ReactNode } from "react";

import { UserAlreadyExistsError } from "../../errors";
import ErrorMessage from "../ErrorMessage";

interface Props {
  // children?: ReactNode;
  fallback: ReactNode;
}

interface State {
  hasError: boolean;
  userAlreadyExistsError?: boolean;
  statusCode?: number;
}

class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    if (error instanceof UserAlreadyExistsError) {
      return { hasError: true, userAlreadyExistsError: true };
    } else {
      console.error("error", error);
      return { hasError: true };
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    const { children, fallback } = this.props;
    const { hasError, userAlreadyExistsError } = this.state;
    if (userAlreadyExistsError) {
      return <ErrorMessage type="user-already-exists" />;
    }
    if (hasError) {
      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
