import { action, category, label, trackEventOpenPage } from "@telia/b2b-web-analytics-wrapper";
import { Action, Label } from "@telia/b2b-web-analytics-wrapper/types/interfaces";

export function trackRequestCode() {
  track(action.INITIATE, label.ONE_TIME_CODE);
}
export function trackVerifyOneTimeCode() {
  track(action.VERIFY, label.ONE_TIME_CODE);
}
export function trackLogin() {
  track(action.INITIATE, label.LOGIN);
}
export function trackFinalize() {
  track(action.SUBMIT, label.LOGIN);
}
export function trackSuccess() {
  track(action.COMPLETED, label.LOGIN);
}

async function track(action: Action, label: Label) {
  await trackEventOpenPage(category.ACCOUNT_INVITATION, action, label);
}
