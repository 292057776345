import { useQuery } from "@tanstack/react-query";
import {
  ContactInfoDTO,
  NoScopeInviteControllerService,
} from "@telia/b2b-rest-client/src/corp-invite-service";

import { getInviteIdFromUrl } from "../utils/utils";

export function useContactInformation() {
  const inviteId = getInviteIdFromUrl();

  return useQuery<ContactInfoDTO>({
    queryKey: ["contact-information", inviteId],
    queryFn: () => worker(inviteId),
  });
}

function worker(inviteId: number): Promise<ContactInfoDTO> {
  return NoScopeInviteControllerService.getRecipientContactInfo(inviteId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.message);
    });
}
