import { useEffect, useRef, useState } from "react";
import { useMessage } from "@messageformat/react";
import { B2xStepper } from "@telia/b2x-stepper/react-cjs";

import { ADD_INFORMATION_STEP } from "../constants";
import AddInformationStep from "./AddInformationStep";
import IdentifyStep from "./IdentifyStep";
import VerifyCodeStep from "./VerifyCodeStep";

enum StepState {
  CODE_NOT_VERIFIED,
  CODE_VERIFIED,
  RETURN_FROM_CIAM,
}

interface StepperProps {
  emitAddUserSuccess: () => void;
  showRequestSmsTokenButton: boolean;
}

export function Stepper(prop: StepperProps) {
  const { emitAddUserSuccess, showRequestSmsTokenButton } = prop;
  const messages = useMessage("stepper");
  const steps = [
    { displayName: messages.firstStep(), revisitable: false },
    { displayName: messages.secondStep(), revisitable: false },
    { displayName: messages.thirdStep(), revisitable: false },
  ];
  const [stepState, setStepState] = useState<StepState>(StepState.CODE_NOT_VERIFIED);
  const [currentStep, setCurrentStep] = useState(0);
  const validationCode = useRef("");

  useEffect(() => {
    checkIfReturnFromCiam();
  }, []);

  const setCodeVerified = () => {
    setStepState(StepState.CODE_VERIFIED);
    setCurrentStep(1);
  };

  const setValidationCode = (_validationCode: string) => {
    validationCode.current = _validationCode;
  };

  const checkIfReturnFromCiam = async () => {
    if (window.location.href.includes(ADD_INFORMATION_STEP)) {
      setStepState(StepState.RETURN_FROM_CIAM);
      setCurrentStep(2);
    }
  };

  const renderStep = () => {
    switch (stepState) {
      case StepState.CODE_NOT_VERIFIED: {
        return (
          <VerifyCodeStep
            slot="0"
            emitVerifyCode={setCodeVerified}
            emitValidationCode={setValidationCode}
            showRequestSmsTokenButton={showRequestSmsTokenButton}
          />
        );
      }

      case StepState.CODE_VERIFIED: {
        return <IdentifyStep slot="1" validationCode={validationCode.current} />;
      }

      case StepState.RETURN_FROM_CIAM: {
        return <AddInformationStep slot="2" emitSuccess={emitAddUserSuccess} />;
      }
    }
  };
  return (
    <B2xStepper steps={JSON.stringify(steps)} currentStep={currentStep} data-testid="stepper">
      {renderStep()}
    </B2xStepper>
  );
}
