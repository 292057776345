import styled from "styled-components";
import { useMessage } from "@messageformat/react";
import { Link } from "@purpur/purpur";
import { B2B_BASE_URL } from "@telia/b2b-utils";
import { B2xResultMessage } from "@telia/b2x-result-message/react-cjs";

export interface ErrorProps {
  type: "technical-error" | "user-already-exists";
}

const MyBusinessLink = styled.div`
  margin-bottom: var(--purpur-spacing-600);
`;

const UserExistsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
function UserExistsError() {
  const messages = useMessage("user-already-exists-error");
  const goToMyBusiness = useMessage("go-to-mybusiness");
  return (
    <UserExistsContainer>
      <B2xResultMessage
        heading={messages.title()}
        completeDescription={messages.preamble()}
        graphic="technical-error"
      />
      <MyBusinessLink>
        <Link variant="navigation" disableVisited>
          <a href={B2B_BASE_URL}>{goToMyBusiness}</a>
        </Link>
      </MyBusinessLink>
    </UserExistsContainer>
  );
}
function TechnicalError() {
  const messages = useMessage("technical-error");
  return (
    <B2xResultMessage
      heading={messages.title()}
      completeDescription={messages.preamble()}
      graphic="technical-error"
    />
  );
}
function ErrorMessage({ type }: ErrorProps) {
  switch (type) {
    case "technical-error":
      return <TechnicalError />;
    case "user-already-exists":
      return <UserExistsError />;
  }
}

export default ErrorMessage;
