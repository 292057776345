import { PropsWithChildren } from "react";
import styled from "styled-components";
import { Grid } from "@purpur/purpur";

const ContentContainer = styled(Grid)`
  margin-top: var(--purpur-spacing-800);
  margin-bottom: var(--purpur-spacing-800);
`;

const Column = styled(Grid.Col)`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;

  border-radius: var(--purpur-border-radius-lg);
  padding: var(--purpur-spacing-600) !important;
  background: var(--purpur-color-background-primary);
`;

type LayoutProps = {
  slot?: string;
  dataTestId: string;
};

function Layout(props: PropsWithChildren<LayoutProps>) {
  const { children, dataTestId, slot } = props;
  return (
    <div slot={slot}>
      <ContentContainer data-testid={dataTestId}>
        <Grid.Row>
          <Column widthLg={6} width={12}>
            {children}
          </Column>
        </Grid.Row>
      </ContentContainer>
    </div>
  );
}

export default Layout;
