import { Controller, useFormContext } from "react-hook-form";
import { useMessage } from "@messageformat/react";

import { StyledCheckbox, StyledFieldError, StyledInput } from "../../../StyledComponents";

export function Checkbox() {
  const messages = useMessage("add-information");

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name="terms"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <>
          <StyledCheckbox id="termsCheckbox" label={messages.termsCheckbox()} {...field} />
          {errors?.terms && <StyledFieldError>{messages.termsCheckboxError()}</StyledFieldError>}
        </>
      )}
    />
  );
}

export function EmailTextInput() {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const messages = useMessage("add-information");
  return (
    <>
      <Controller
        name="email"
        control={control}
        rules={{
          required: { value: true, message: messages.emailRequired() },
          pattern: { value: /^[^@]+@[^@]+\.[^@]+$/, message: messages.emailValidationError() },
        }}
        render={({ field: { onChange, value } }) => (
          <StyledInput
            id="emailInput"
            type="text"
            label={`* ${messages.email()}`}
            onInput={onChange}
            value={value}
          />
        )}
      />
      {errors.email && <StyledFieldError>{messages.emailValidationError()}</StyledFieldError>}
    </>
  );
}

export function TelephoneTextInput() {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const messages = useMessage("add-information");

  return (
    <div style={{ margin: "var(--purpur-spacing-200) 0 var(--purpur-spacing-300) 0" }}>
      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          required: false,
          pattern: {
            value: /^(\+|00)?[0-9]{6,15}$/,
            message: messages.phoneNumberValidationError(),
          },
        }}
        render={({ field: { onChange, value } }) => (
          <>
            <StyledInput
              id="phoneNumberInput"
              type="tel"
              label={messages.phoneNumber()}
              onInput={onChange}
              value={value}
            />
            {errors.phoneNumber && (
              <StyledFieldError>{messages.phoneNumberValidationError()}</StyledFieldError>
            )}
          </>
        )}
      />
    </div>
  );
}
