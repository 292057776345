import { useEffect, useState } from "react";
import { useMessage } from "@messageformat/react";
import { Heading } from "@purpur/purpur";
import { ApiError } from "@telia/b2b-rest-client/src/corp-invite-service";

import { useRequestSmsToken } from "../../hooks/useRequestSmsToken";
import { trackRequestCode } from "../../utils/analytics";
import Layout from "../Layout";
import { Button, PaddedParagraph, StyledFieldError } from "../StyledComponents";
import { trackRequestNewToken, trackVerificationTokenReceived } from "../../utils/ga4Analytics";

export interface StepContentProps {
  emitTokenReceived: (success: boolean) => void;
  maskedMobilePhoneNumber: string;
}

enum REQUEST_SMS_ERROR {
  ALREADY_SENT,
  TECHNICAL_ERROR,
}

function RequestCode(props: StepContentProps) {
  const { maskedMobilePhoneNumber, emitTokenReceived } = props;
  const [requestTokenCoolDown, setRequestTokenCoolDown] = useState<boolean>(false);
  const [error, setError] = useState<REQUEST_SMS_ERROR>();
  const {
    isError,
    error: requestSmsTokenError,
    isSuccess,
    mutate: requestSmsToken,
  } = useRequestSmsToken();

  const technicalErrorMessage = useMessage("technicalError");
  const messages = useMessage("request-code");
  const codeCoolDownText = useMessage("codeCoolDown");
  const preamble = useMessage("request-code.preamble", {
    maskedMobilePhoneNumber: maskedMobilePhoneNumber,
  });
  const CODE_COOL_DOWN = 5 * 60 * 1000;
  const handleRequestSmsToken = () => {
    setRequestTokenCoolDown(true);
    trackRequestNewToken();
    requestSmsToken();

    setTimeout(() => {
      setRequestTokenCoolDown(false);
    }, CODE_COOL_DOWN);
  };
  useEffect(() => {
    if (isSuccess) {
      trackVerificationTokenReceived();
      trackRequestCode();

      emitTokenReceived(true);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (!(requestSmsTokenError instanceof ApiError)) {
      return;
    }
    if (requestSmsTokenError.status === 409) {
      setError(REQUEST_SMS_ERROR.ALREADY_SENT);
    } else if (requestSmsTokenError.status != 200) {
      setError(REQUEST_SMS_ERROR.TECHNICAL_ERROR);
    }
  }, [requestSmsTokenError]);

  return (
    <Layout dataTestId="request-code">
      <Heading tag="h1" variant="title-300">
        {messages.title()}
      </Heading>
      <PaddedParagraph variant="preamble-100">{preamble}</PaddedParagraph>
      <Button
        variant="primary"
        type="button"
        size="md"
        fullWidth={false}
        onClick={() => handleRequestSmsToken()}
        data-testid="button-request-code"
        disabled={requestTokenCoolDown}
      >
        {messages.button()}
      </Button>
      {error === REQUEST_SMS_ERROR.ALREADY_SENT && requestTokenCoolDown && (
        <StyledFieldError data-testid="code-cool-down">{codeCoolDownText}</StyledFieldError>
      )}
      {error === REQUEST_SMS_ERROR.TECHNICAL_ERROR && (
        <StyledFieldError data-testid="technical-error-message">
          {technicalErrorMessage}
        </StyledFieldError>
      )}
    </Layout>
  );
}

export default RequestCode;
