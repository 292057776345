import styled from "styled-components";
import { useMessage } from "@messageformat/react";
import { B2xNakedHero } from "@telia/b2x-naked-hero/react-cjs";

type HeroProps = {
  invitedBy: string;
  organisation: string;
};

const InviteHero = styled(B2xNakedHero)`
  background-color: var(--purpur-color-purple-900);
  color: var(--purpur-color-brand-white);
  padding: var(--purpur-spacing-1000) var(--purpur-spacing-200);
`;

export default function Hero(props: HeroProps) {
  const { invitedBy, organisation } = props;

  const heading = useMessage("hero.heading");
  const preamble = useMessage("hero.preamble", {
    invitedBy: invitedBy,
    organisation: organisation,
  });

  return (
    <section data-testid="hero">
      <InviteHero heading={heading} preamble={preamble} />
    </section>
  );
}

// function HeroComponent() {
//   return (
//       <Hero
//           organisation={inviteInformation?.organisation ?? ""}
//           invitedBy={inviteInformation?.invitedBy ?? ""}
//       />
//   );
// }
