import { useQuery } from "@tanstack/react-query";
import {
  GetInviteHeaderResponseDTO,
  NoScopeInviteControllerService,
} from "@telia/b2b-rest-client/src/corp-invite-service";
import { trackInvitationInvalid } from "../utils/ga4Analytics";

import { getInviteIdFromUrl, getNonceFromUrl } from "../utils/utils";

export interface InviteInformation {
  invitedBy: string;
  organisation: string;
  shouldSendSmsCode: boolean;
  maskedMobilePhoneNumber: string;
}

export interface InviteInformationError {
  message: string;
}
export function useInviteInformation() {
  const inviteId = getInviteIdFromUrl();
  const nonce = getNonceFromUrl();

  return useQuery<InviteInformation, InviteInformationError>({
    queryKey: ["invite-information", inviteId],
    queryFn: () => worker(inviteId, nonce),
  });
}

function worker(inviteId: number, nonce: string): Promise<InviteInformation> {
  return NoScopeInviteControllerService.getInviteHeader(inviteId, nonce)
    .then((response: GetInviteHeaderResponseDTO) => {
      return {
        invitedBy: response.inviterFirstName,
        organisation: response.customerGroupName,
        shouldSendSmsCode: response.smsVerificationRequired,
        maskedMobilePhoneNumber: response.maskedMobilePhoneNumber,
      };
    })
    .catch((error: unknown) => {
      if (error instanceof Error) {
        return Promise.reject(error.message);
      } else {
        trackInvitationInvalid();
        return Promise.reject(error);
      }
    });
}
