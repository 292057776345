import { getGa4InviteUserAccountParams, getGa4StepParams, trackGa4 } from "@telia/b2b-analytics";

export function trackUserAccountInitiate(isLoggedIn: boolean) {
  trackGa4("user_account_initiate", {
    ...getGa4InviteUserAccountParams(isLoggedIn),
    ...getGa4StepParams("user_account_initiate", 1),
  });
}

export function trackRequestNewToken() {
  trackGa4("user_account_progress", {
    ...getGa4InviteUserAccountParams(),
    ...getGa4StepParams("request_new_verification_token", 2),
  });
}
export function trackVerificationTokenReceived() {
  trackGa4("user_account_progress", {
    ...getGa4InviteUserAccountParams(),
    ...getGa4StepParams("verification_token_received", 2),
  });
}

export function trackBankIdVerification() {
  trackGa4("user_account_progress", {
    ...getGa4InviteUserAccountParams(),
    ...getGa4StepParams("bankid_identification", 3),
  });
}

export function trackCodeInput() {
  trackGa4("user_account_progress", {
    ...getGa4InviteUserAccountParams(),
    ...getGa4StepParams("code_input", 2),
  });
}

export function trackUserAccountComplete() {
  trackGa4("user_account_complete", {
    ...getGa4InviteUserAccountParams(true),
    ...getGa4StepParams("user_account_complete"),
  });
}

// Error tracking

export function trackInvitationInvalid() {
  trackGa4("user_account_fail", {
    ...getGa4InviteUserAccountParams(),
    ...{ error_message: "Invitation is invalid", error_type: "invitation_invalid" },
  });
}

export function trackUserAccountFail() {
  trackGa4("user_account_fail", {
    ...getGa4InviteUserAccountParams(true),
    ...getGa4StepParams("user_account_complete"),
    error_message: "Error occurred when accepting invite",
    error_type: "error_accepting_invite",
  });
}

export function trackUnableToRequestToken() {
  trackGa4("user_account_fail", {
    ...getGa4InviteUserAccountParams(),
    ...getGa4StepParams("code_input"),
    error_message: "Unable to request new verification code",
    error_type: "error_requesting_verification_token",
  });
}

export function trackUserAlreadyExists() {
  trackGa4("user_account_fail", {
    step_label: "user_account_complete",
    ...getGa4InviteUserAccountParams(),
    ...{ error_message: "This user account already exists", error_type: "user_already_exists" },
  });
}
