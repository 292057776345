import { useQuery } from "@tanstack/react-query";
import {
  NonceAndSmsTokenDTO,
  NoScopeInviteControllerService,
  ValidationResponseDTO,
} from "@telia/b2b-rest-client/src/corp-invite-service";

import { getInviteIdFromUrl, getNonceFromUrl } from "../utils/utils";

export type TokenInformation = {
  valid: boolean;
  validationCode: string;
};

export function useValidateSmsToken(code?: string) {
  const nonce = getNonceFromUrl();
  const inviteId = getInviteIdFromUrl();
  return useQuery<TokenInformation>({
    queryKey: ["token-information", inviteId, code],
    queryFn: () => worker(inviteId, nonce, code),
    enabled: !!code,
  });
}
function worker(
  inviteId: number,
  nonce: string,
  smsToken: string | undefined
): Promise<TokenInformation> {
  if (!smsToken) {
    return Promise.reject({ valid: false, validationCode: "" });
  }
  const body: NonceAndSmsTokenDTO = { nonce: nonce, smsToken: smsToken };
  return NoScopeInviteControllerService.validateSmsToken(inviteId, body)
    .then((response: ValidationResponseDTO) => {
      return { valid: true, validationCode: response.validationCode };
    })
    .catch(() => {
      return { valid: false, validationCode: "" };
    });
}
