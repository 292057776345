import { useQuery } from "@tanstack/react-query";
import { AuthInfoDTO, IsLoggedInControllerService } from "@telia/b2b-rest-client/src/corp-login";

import { getInviteIdFromUrl, getNonceFromUrl } from "../utils/utils";
import {
  B2B_ACCEPT_INVITE_URL,
  B2B_ACCEPT_INVITE_URL_LOGGED_IN_URL,
  B2B_ACCEPT_INVITE_URL_NOT_LOGGED_IN_URL,
} from "@telia/b2b-utils";
import { trackUserAccountInitiate } from "../utils/ga4Analytics";

const acceptInviteIsOnInvokeUrl = (pathname: string) =>
  pathname.includes(B2B_ACCEPT_INVITE_URL) &&
  !pathname.includes(B2B_ACCEPT_INVITE_URL_LOGGED_IN_URL) &&
  !pathname.includes(B2B_ACCEPT_INVITE_URL_NOT_LOGGED_IN_URL);

export function useIsLoggedIn() {
  const inviteId = getInviteIdFromUrl();
  const nonce = getNonceFromUrl();
  return useQuery<boolean | undefined>({
    queryKey: ["isLoggedIn", inviteId, nonce],
    queryFn: worker,
  });

  function worker() {
    return IsLoggedInControllerService.isLoggedIn()
      .then((value: AuthInfoDTO) => {
        if (acceptInviteIsOnInvokeUrl(window.location.pathname)) {
          // Should be sent on first access - i.e. on the url clicked from the mail

          trackUserAccountInitiate(!!value.isLoggedIn);
        }
        return Promise.resolve(value.isLoggedIn);
      })
      .catch(() => Promise.reject());
  }
}
