
export default {
  hero: {
    heading: () => "Inbjudan till MyBusiness",
    preamble: (d) => d.invitedBy + " har bjudit in dig som användare av " + d.organisation
  },
  "technical-error": {
    title: () => "Något gick fel!",
    preamble: () => "Ett tekniskt fel inträffade. Det arbetas på en lösning. Försök igen snart."
  },
  "user-already-exists-error": {
    title: () => "Användaren finns redan",
    preamble: () => "Den användare som du försöker acceptera inbjudan på finns redan på organisationen."
  },
  "go-to-mybusiness": () => "Till MyBusiness",
  stepper: {
    firstStep: () => "Verifiera kod",
    secondStep: () => "Identifiera dig",
    thirdStep: () => "Slutför"
  },
  codeCoolDown: () => "Du behöver vänta i upp till fem minuter innan du kan hämta en ny engångskod.",
  technicalError: () => "Ett tekniskt fel inträffade. Det arbetas på en lösning. Försök igen snart",
  "request-code": {
    title: () => "Hämta engångskod",
    preamble: (d) => "För att komma vidare behöver du en engångskod. Klicka på knappen nedan för att hämta en till " + d.maskedMobilePhoneNumber + ".",
    button: () => "Hämta engångskod"
  },
  "verify-code": {
    title: () => "Skriv in din engångskod",
    preamble: () => "Skriv i engångskoden nedan och välj Bekräfta",
    inputLabel: () => "Engångskod",
    button: () => "Bekräfta",
    codeError: () => "Vi kunde tyvärr inte verifiera engångskoden. Kontrollera att du skrivit in rätt eller hämta en ny kod",
    link: () => "Hämta ny engångskod"
  },
  identify: {
    title: () => "Identifiera dig",
    preamble: () => "Koden är verifierad! I nästa steg identifierar du dig genom att logga in med BankID eller din Telia-Profil.",
    button: () => "Logga in och identifiera dig"
  },
  "add-information": {
    title: () => "Snart klar!",
    preamble: () => "Du får snart tillgång till MyBusiness. Det återstår bara att fylla i dina kontaktuppgifter så vi kan nå dig när det behövs. ",
    phoneNumber: () => "Telefonnummer",
    phoneNumberValidationError: () => "Telefonnumret är inte giltigt",
    email: () => "Mejladress",
    emailValidationError: () => "Mejladressen är inte giltig",
    emailRequired: () => "Mejladressen är obligatoriskt",
    button: () => "Slutför",
    termsLink: () => "Användarvillkor for MyBusiness",
    termsCheckbox: () => "Jag godkänner användarvillkoren",
    termsCheckboxError: () => "Du måste godkänna användarvillkoren"
  },
  "invite-error": {
    title: () => "Denna inbjudan är inte giltig.",
    preamble: () => "Det kan vara att inbjudan redan använts till att skapa ett konto. Be om en ny inbjudan om detta är ett fel."
  },
  success: {
    title: () => "Redo för avfärd!",
    preamble: () => "Svårare än så var det inte. Nu är du inloggad i MyBusiness. Portalen där du beställer, hanterar, administrerar och får hjälp med dina tjänster hos oss.",
    button: () => "Gå vidare till MyBusiness"
  }
}