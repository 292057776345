import styled from "styled-components";
import { Button as PuppeButton } from "@purpur/purpur";
import { Checkbox } from "@purpur/purpur";
import { FieldErrorText } from "@purpur/purpur";
import { Paragraph } from "@purpur/purpur";
import { TextField } from "@purpur/purpur";

export const Button = styled(PuppeButton)`
  margin-top: var(--purpur-spacing-600);
`;

export const PaddedParagraph = styled(Paragraph)`
  margin-top: var(--purpur-spacing-300);
  margin-bottom: var(--purpur-spacing-300);
`;
export const StyledInput = styled(TextField)`
  margin-bottom: var(--purpur-spacing-200);
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-top: var(--purpur-spacing-200);
`;

export const StyledFieldError = styled(FieldErrorText)`
  color: var(--purpur-color-red-500);
  margin-top: var(--purpur-spacing-200);
`;
