import styled from "styled-components";
import { Skeleton } from "@purpur/purpur";

import { SectionContainer } from "./SectionContainer";

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--purpur-color-background-secondary);
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: var(--purpur-spacing-1000) var(--purpur-spacing-200);
  background-color: var(--purpur-color-purple-900);
  color: var(--purpur-color-brand-white);
`;

const HeadingSkeleton = styled(Skeleton)`
  height: 5rem;
  width: 45rem;
  max-width: 90vw;
  margin-bottom: calc(2rem * var(--voca-rem-multiplier));
  background-color: var(--purpur-color-brand-white);
`;

const PreambleSkeleton = styled(Skeleton)`
  height: 2.5rem;
  width: 65rem;
  max-width: 90vw;
  background-color: var(--purpur-color-brand-white);
`;

function InviteSkeleton() {
  return (
    <SkeletonContainer>
      <HeroContainer>
        <HeadingSkeleton />
        <PreambleSkeleton />
      </HeroContainer>
      <SectionContainer />
    </SkeletonContainer>
  );
}
export default InviteSkeleton;
