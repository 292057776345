import styled from "styled-components";
import { Skeleton } from "@purpur/purpur";

const SectionContainerSkeleton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--purpur-spacing-800) auto;
  padding: 0 calc(2.4rem * var(--voca-rem-multiplier));
  max-width: calc(144rem * var(--voca-rem-multiplier));
  width: 100vw;
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 95vw;
  width: calc(((100vw - 1px) / 12) * 12);
  border-radius: var(--purpur-border-radius-lg);
  padding: var(--purpur-spacing-600);
  background-color: var(--purpur-color-brand-white);
`;

export function SectionContainer() {
  return (
    <SectionContainerSkeleton>
      <CardContainer>
        <Skeleton style={{ height: "3.5rem", width: "25rem", maxWidth: "75vw" }} />
        <Skeleton style={{ height: "2rem", width: "35rem", marginTop: "2rem", maxWidth: "75vw" }} />
        <Skeleton
          style={{ height: "2rem", width: "25rem", marginTop: "0.5rem", maxWidth: "75vw" }}
        />
        <Skeleton
          style={{ height: "4.5rem", width: "28rem", marginTop: "8rem", maxWidth: "50vw" }}
        />
      </CardContainer>
    </SectionContainerSkeleton>
  );
}
