import { PropsWithChildren } from "react";
import { useMessage } from "@messageformat/react";
import { Button } from "@purpur/purpur";
import { Heading } from "@purpur/purpur";
import { invokeLoginFlow } from "@telia/b2b-corp-login";
import * as b2bI18n from "@telia/b2b-i18n";

import { trackLogin } from "../../../utils/analytics";
import { constructCiamUrl } from "../../../utils/utils";
import Layout from "../../Layout";
import { PaddedParagraph } from "../../StyledComponents";
import { trackBankIdVerification } from "../../../utils/ga4Analytics";

export interface StepContentProps {
  slot: string;
  validationCode: string;
}

function trackingOnClick() {
  trackBankIdVerification();
  trackLogin();
}

function IdentifyStep(props: PropsWithChildren<StepContentProps>) {
  const { slot, children, validationCode } = props;
  const messages = useMessage(`identify`);
  return (
    <Layout slot={slot} dataTestId="identify-step">
      <Heading tag="h1" variant="title-300">
        {messages.title()}
      </Heading>
      <PaddedParagraph variant="preamble-100">{messages.preamble()}</PaddedParagraph>
      {children}
      <Button
        variant="primary"
        type="button"
        size="md"
        fullWidth={false}
        onClick={() => {
          trackingOnClick();
          invokeLoginFlow(constructCiamUrl(validationCode), b2bI18n.currentLanguage(), "login");
        }}
      >
        {messages.button()}
      </Button>
    </Layout>
  );
}
export default IdentifyStep;
