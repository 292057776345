import { useMessage } from "@messageformat/react";
import { B2xResultMessage } from "@telia/b2x-result-message/react-cjs";

function InviteError() {
  const messages = useMessage("invite-error");
  return (
    <B2xResultMessage
      heading={messages.title()}
      completeDescription={messages.preamble()}
      graphic="technical-error"
    />
  );
}

export default InviteError;
