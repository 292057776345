
export default {
  hero: {
    heading: () => "Invitation to MyBusiness",
    preamble: (d) => d.invitedBy + " has invited you as a user of " + d.organisation
  },
  "technical-error": {
    title: () => "Something went wrong!",
    preamble: () => "A technical error occurred. A solution is being worked on. Try again soon."
  },
  "user-already-exists-error": {
    title: () => "The user already exists",
    preamble: () => "The user accepting this invite already exists in the organisation."
  },
  "go-to-mybusiness": () => "Go to MyBusiness",
  stepper: {
    firstStep: () => "Verify code",
    secondStep: () => "Identify yourself",
    thirdStep: () => "Complete"
  },
  codeCoolDown: () => "Please wait up to five minutes before re-requesting a new one-time code",
  technicalError: () => "A technical error has occurred. We're working on a solution, please wait a while and try again.",
  "request-code": {
    title: () => "Get a one-time code",
    preamble: (d) => "To proceed, you need a one-time code. Click the button below to get one for " + d.maskedMobilePhoneNumber + ".",
    button: () => "Get a one-time code"
  },
  "verify-code": {
    title: () => "Type in the one-time code",
    preamble: () => "Type in the one-time code below and select Confirm.",
    inputLabel: () => "One-time code",
    button: () => "Confirm",
    codeError: () => "Unfortunately, we could not verify the one-time code. Check that you typed it correctly or get a new code.",
    link: () => "Resend one-time code"
  },
  identify: {
    title: () => "Identify yourself",
    preamble: () => "The code is verified! In the next step, you identify yourself by logging in with BankID or your Telia-Profile.",
    button: () => "Log in and identify yourself"
  },
  "add-information": {
    title: () => "Almost finished!",
    preamble: () => "You will soon have access to MyBusiness. All that remains is to fill in your contact details so we can reach you when needed.",
    phoneNumber: () => "Phone number",
    phoneNumberValidationError: () => "Invalid phone number",
    email: () => "Mail address",
    emailValidationError: () => "Invalid mail address",
    emailRequired: () => "Mail address is required",
    button: () => "Complete",
    termsLink: () => "Terms of use for MyBusiness",
    termsCheckbox: () => "I accept the terms of use.",
    termsCheckboxError: () => "You need to accept the terms of use"
  },
  "invite-error": {
    title: () => "The invitation is not valid.",
    preamble: () => "It's possible that the invitation has already been used to create an account. Ask for a new invite if this is incorrect."
  },
  success: {
    title: () => "Ready for departure!",
    preamble: () => "It wasn't harder than that. You are now logged in to MyBusiness. The portal where you order, manage, administer, and get support from us.",
    button: () => "Continue to MyBusiness"
  }
}