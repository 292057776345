import { useMutation } from "@tanstack/react-query";
import {
  ApiError,
  NonceDTO,
  NoScopeInviteControllerService,
} from "@telia/b2b-rest-client/src/corp-invite-service";
import { trackUnableToRequestToken } from "../utils/ga4Analytics";

import { convertToApiError, getInviteIdFromUrl, getNonceFromUrl } from "../utils/utils";

export interface RequestTokenResult {
  smsCodeSent: boolean;
}

export function useRequestSmsToken() {
  const nonce = getNonceFromUrl();
  const inviteId = getInviteIdFromUrl();
  return useMutation<RequestTokenResult, ApiError | any>({
    mutationKey: ["request-sms-code", nonce],
    mutationFn: () => worker(inviteId, nonce),
  });
}

function worker(inviteId: number, nonce: string): Promise<RequestTokenResult> {
  const body: NonceDTO = { nonce: nonce };
  return NoScopeInviteControllerService.requestSmsToken(inviteId, body)
    .then(() => {
      return {
        smsCodeSent: true,
      };
    })
    .catch((error) => {
      trackUnableToRequestToken();

      try {
        const apiError = convertToApiError(error);
        return Promise.reject(apiError);
      } catch {
        return Promise.reject(error);
      }
    });
}
