import { ApiError } from "@telia/b2b-rest-client/src/corp-invite-service";
import {
  B2B_ACCEPT_INVITE_URL_LOGGED_IN_URL,
  B2B_ACCEPT_INVITE_URL_NOT_LOGGED_IN_URL,
} from "@telia/b2b-utils";

import { ADD_INFORMATION_STEP, VALIDATION_CODE } from "../components/constants";

const INVITE_ID_INDEX = 1;
const NONCE_ID_INDEX = 2;
const NonceAndInviteIdExpression =
  /(\d{1,8})\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/gm;

export const getNonceFromUrl = (): string => {
  // const urlArray = getSlashSeperatedUrl();
  // const nonce = urlArray[NONCE_ID_INDEX];
  const matchedValuesIterator = window.location.pathname.matchAll(NonceAndInviteIdExpression);
  const matchedValuesNestedArray = [...matchedValuesIterator];

  if (matchedValuesNestedArray.length == 0) {
    throw new Error("Received an invalid invite URL");
  }
  const nonce = matchedValuesNestedArray[0][NONCE_ID_INDEX];
  return nonce;
};
export const getValidationCodeFromUrl = (): string => {
  const params = new URLSearchParams(window.location.search);
  return params.get(VALIDATION_CODE) ?? "";
};

export const getInviteIdFromUrl = (): number => {
  const matchedValuesIterator = window.location.pathname.matchAll(NonceAndInviteIdExpression);
  const matchedValuesNestedArray = [...matchedValuesIterator];
  if (matchedValuesNestedArray.length == 0) {
    return NaN;
  }
  const inviteId = matchedValuesNestedArray[0][INVITE_ID_INDEX];

  return Number(inviteId);
};

export const convertToApiError = (error: any) => {
  try {
    const response = {
      url: error.url,
      ok: error.ok,
      status: error.status,
      statusText: error.statusText,
      body: error.body,
    };
    return new ApiError(response, "");
  } catch {
    throw new Error("Unable to convert error to ApiError");
  }
};

export function constructUrl(isLoggedIn: boolean): string {
  const nonce = getNonceFromUrl();
  const inviteId = getInviteIdFromUrl();
  const baseUrl = isLoggedIn
    ? B2B_ACCEPT_INVITE_URL_LOGGED_IN_URL
    : B2B_ACCEPT_INVITE_URL_NOT_LOGGED_IN_URL;
  const returnFromCiam = window.location.pathname.includes(ADD_INFORMATION_STEP)
    ? ADD_INFORMATION_STEP
    : "";
  return baseUrl + "/" + inviteId + "/" + nonce + returnFromCiam + window.location.search;
}

export function constructCiamUrl(validationCode: string): string {
  return constructUrl(true) + ADD_INFORMATION_STEP + `?${VALIDATION_CODE}=${validationCode}`;
}
