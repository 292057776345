import { useMutation } from "@tanstack/react-query";
import { NoScopeInviteControllerService } from "@telia/b2b-rest-client/src/corp-invite-service";
import { trackUserAccountComplete, trackUserAccountFail } from "../utils/ga4Analytics";

import { getInviteIdFromUrl } from "../utils/utils";

export interface AccessProfileId {
  accessProfile?: number;
}
export interface AddUserInputs {
  validationCode: string;
  email: string;
  phoneNumber?: string;
}

export function useAddUser() {
  const inviteId = getInviteIdFromUrl();

  return useMutation({
    mutationKey: ["add-user", inviteId],
    mutationFn: (addUserInputs: AddUserInputs) => worker(inviteId, addUserInputs),
    // onError: (error) => {
    //   console.log(error, "onError");
    // },
  });
}

function worker(inviteId: number, addUserInputs: AddUserInputs): Promise<AccessProfileId> {
  const { validationCode, phoneNumber, email } = addUserInputs;
  if (!addUserInputs.validationCode) {
    return Promise.reject("Validation code missing");
  }
  const body = {
    validationCode: validationCode,
    email: email,
    phoneNumber: phoneNumber,
  };
  return NoScopeInviteControllerService.acceptInvite(inviteId, body)
    .then((response) => {
      trackUserAccountComplete();
      return Promise.resolve({ accessProfile: response.accessProfileId });
    })
    .catch((reason) => {
      trackUserAccountFail();
      return Promise.reject(reason);
    });
}
