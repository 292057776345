import styled from "styled-components";
import { useMessage } from "@messageformat/react";
import { CtaLink } from "@purpur/purpur";
import { Grid } from "@purpur/purpur";
import { B2B_BASE_URL } from "@telia/b2b-utils";
import { B2xResultMessage } from "@telia/b2x-result-message/react-cjs";

import { trackSuccess } from "../../utils/analytics";

const Column = styled(Grid.Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--purpur-spacing-1600);
`;

function Success() {
  trackSuccess();
  const messages = useMessage("success");
  return (
    <Grid data-testid="success">
      <Grid.Row>
        <Column widthLg={8}>
          <B2xResultMessage
            heading={messages.title()}
            completeDescription={messages.preamble()}
            graphic="getting-started"
          />
          <CtaLink href={B2B_BASE_URL} variant="primary">
            {messages.button()}
          </CtaLink>
        </Column>
      </Grid.Row>
    </Grid>
  );
}

export default Success;
