import { useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMessage } from "@messageformat/react";
import { Heading } from "@purpur/purpur";
import { external, Icon } from "@purpur/purpur";
import { Link } from "@purpur/purpur";
import { Spinner } from "@purpur/purpur";
import { ApiError } from "@telia/b2b-rest-client/src/corp-invite-service";
import { B2B_TERMS_URL } from "@telia/b2b-utils";

import { UserAlreadyExistsError } from "../../../errors";
import { AddUserInputs, useAddUser } from "../../../hooks/useAddUser";
import { useContactInformation } from "../../../hooks/useContactInformation";
import { getValidationCodeFromUrl } from "../../../utils/utils";
import { SectionContainer } from "../../InviteSkeleton/SectionContainer";
import Layout from "../../Layout";
import { Button, PaddedParagraph, StyledFieldError } from "../../StyledComponents";
import { Checkbox, EmailTextInput, TelephoneTextInput } from "./FormComponents";
import { trackFinalize } from "../../../utils/analytics";
import { trackUserAlreadyExists } from "../../../utils/ga4Analytics";

interface AddInformationStepProps {
  slot: string;
  emitSuccess: () => void;
}
interface FormMembers {
  email: string;
  phoneNumber?: string;
  terms: boolean;
}

function AddInformationStep(props: AddInformationStepProps) {
  const { slot, emitSuccess } = props;
  const { data, isFetched } = useContactInformation();
  const { mutate: addUser, isSuccess, isError: isAddUserError, error } = useAddUser();

  const defaultValues = useMemo(
    () => ({
      email: data?.email ?? "",
      phoneNumber: data?.phoneNumber ?? "",
      terms: false,
    }),
    [data]
  );
  const methods = useForm<FormMembers>({ defaultValues: defaultValues });
  const { reset } = methods;
  const messages = useMessage("add-information");
  const technicalError = useMessage("technicalError");

  useEffect(() => {
    if (isFetched) {
      reset(defaultValues);
    }
  }, [reset, defaultValues, isFetched]);

  useEffect(() => {
    if (isAddUserError) {
      if (error instanceof ApiError && error.status === 409) {
        trackUserAlreadyExists();
        throw new UserAlreadyExistsError("User already exists");
      }
      return;
    }
    if (isSuccess) {
      trackFinalize();
      return emitSuccess();
    }
  }, [emitSuccess, isSuccess, isAddUserError, error]);

  const onSubmit: SubmitHandler<FormMembers> = (members) => {
    const validationCode = getValidationCodeFromUrl();
    addUser({ validationCode: validationCode, ...members } as AddUserInputs);
  };

  if (!isFetched) {
    return (
      <Layout slot={slot} dataTestId="add-information-step-skeleton">
        <SectionContainer />
      </Layout>
    );
  }
  return (
    <Layout slot={slot} dataTestId="add-information-step">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Heading tag="h1" variant="title-300">
            {messages.title()}
          </Heading>
          <PaddedParagraph variant="preamble-100">{messages.preamble()} </PaddedParagraph>
          <EmailTextInput />
          <TelephoneTextInput />
          <Link variant="standalone">
            <a href={B2B_TERMS_URL} target="_blank" rel="noreferrer">
              {messages.termsLink()} <Icon svg={external} size="xs" />
            </a>
          </Link>
          <Checkbox />
          <Button
            style={{ paddingTop: "2rem" }}
            variant="primary"
            type="submit"
            size="md"
            fullWidth={false}
          >
            {messages.button()}
            {methods.formState.isSubmitting && <Spinner size="sm" variant="negative" />}
          </Button>
          {isAddUserError && <StyledFieldError> {technicalError}</StyledFieldError>}
        </form>
      </FormProvider>
    </Layout>
  );
}
export default AddInformationStep;
